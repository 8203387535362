import React from 'react';
import { Link, graphql } from 'gatsby';
import ReactGA from 'react-ga';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';
import Share from '../components/share';
import { ThemeProvider } from '../providers/ThemeProvider';
import { getGoogleAnalysticsID } from '../utils/constants';

ReactGA.initialize(getGoogleAnalysticsID());

class BlogPostTemplate extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentSearch:
                typeof window !== 'undefined' ? window.location.search : '',
        };
    }

    render() {
        const post = this.props.data.markdownRemark;
        const siteTitle = this.props.data.site.siteMetadata.title;
        const { previous, next } = this.props.pageContext;

        return (
            <ThemeProvider>
                <Layout
                    key={`Post-${this.props.location.pathname}`}
                    location={this.props.location}
                    title={siteTitle}
                >
                    <SEO
                        title={post.frontmatter.title}
                        description={
                            post.frontmatter.description || post.excerpt
                        }
                        image={post.frontmatter.image}
                    />
                    <h1>{post.frontmatter.title}</h1>
                    <p
                        style={{
                            ...scale(-1 / 5),
                            display: 'block',
                            marginBottom: rhythm(1),
                            marginTop: rhythm(-1),
                        }}
                    >
                        {post.frontmatter.date}
                    </p>
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />

                    <Share />

                    <hr
                        style={{
                            marginBottom: rhythm(1),
                            backgroundColor: '#a99898',
                        }}
                    />
                    {/* <Bio /> */}

                    <ul
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            listStyle: 'none',
                            padding: 0,
                        }}
                    >
                        <li>
                            {previous && (
                                <Link
                                    to={`${previous.fields.slug}${this.state.currentSearch}`}
                                    rel="prev"
                                >
                                    ← {previous.frontmatter.title}
                                </Link>
                            )}
                        </li>
                        <li>
                            {next && (
                                <Link
                                    to={`${next.fields.slug}${this.state.currentSearch}`}
                                    rel="next"
                                >
                                    {next.frontmatter.title} →
                                </Link>
                            )}
                        </li>
                    </ul>
                </Layout>
            </ThemeProvider>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                image
            }
        }
    }
`;
