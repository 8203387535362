import React from 'react'
import ReactGA from 'react-ga'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    LinkedinIcon,
} from 'react-share'

const ICON_SIZE = 40

const Share = () => {
    const url =
        typeof window !== 'undefined' ? window.location.href : 'daonamtien.com'

    const trackGA = action => {
        ReactGA.event({
            category: 'Share',
            action,
            label: url,
            value: url,
        })
    }

    return (
        <React.Fragment>
            Share post via:
            <div className="share-container">
                <FacebookShareButton
                    beforeOnClick={() => trackGA('facebook')}
                    url={url}
                    className="share-button"
                >
                    <FacebookIcon size={ICON_SIZE} round />
                </FacebookShareButton>
                <LinkedinShareButton
                    beforeOnClick={() => trackGA('linkedin')}
                    url={url}
                    className="share-button"
                >
                    <LinkedinIcon size={ICON_SIZE} round />
                </LinkedinShareButton>
                <TwitterShareButton
                    beforeOnClick={() => trackGA('twitter')}
                    url={url}
                    className="share-button"
                >
                    <TwitterIcon size={ICON_SIZE} round />
                </TwitterShareButton>
                <TelegramShareButton
                    beforeOnClick={() => trackGA('telegram')}
                    url={url}
                    className="share-button"
                >
                    <TelegramIcon size={ICON_SIZE} round />
                </TelegramShareButton>
            </div>
        </React.Fragment>
    )
}

export default Share
